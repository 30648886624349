







































import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { ChatDto } from '../../dtos/chat.dto'
const ChatModule = namespace("ChatModule");
const Auth = namespace("Auth");

@Component
export default class ChatDashboard extends Vue {
  private loading = false;

  @ChatModule.State("chats")
  private chats!: ChatDto[];

  @ChatModule.Action
  private loadChats!: (userId: string) => Promise<void>;

  @Auth.State("user")
  private currentUser!: any;

  async mounted() {
    await this.loadChats(this.currentUser?.id);
  }


}
